import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../../components/RawHTML';
import Container from '../../../../../../components/Container';

const html = `<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">PDFNet/PDFNetOBJC.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTExternalAnnotManager GetLastJSON]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PDFNet/PDFViewCtrl.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:downloadEventType:pageNumber:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrlStreamingEventMessageUserInfoKey</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:downloadEventType:pageNumber:message:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnnotStyle.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTAnnotStyleKeyFont</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotStyleDelegate annotStyle:fontNameDidChange:]</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotStyle initWithAnnot:onPDFDoc:]</div>
<div class="difference"><span class="status added">Added</span> PTAnnotStyle.fontName</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnnotStyleViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTAnnotStyleViewController.fontPickerConfiguration</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTColorDefaults.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> #def ATTRIBUTE_FREETEXT_FONT</div>
<div class="difference"><span class="status added">Added</span> +[PTColorDefaults setDefaultFreeTextFontName:]</div>
<div class="difference"><span class="status added">Added</span> +[PTColorDefaults defaultFreeTextFontName]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.additionalHTTPHeaders</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTFreeTextAnnotationOptions.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> PTFreeTextAnnotationOptions.defaultFontName</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTFreeTextCreate.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> +[PTFreeTextCreate refreshAppearanceForAnnot:onDoc:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTSelectionRectContainerView.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTSelectionRectContainerView removeLiveAppearance]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTSelectionRectView.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTSelectionRectView removeLiveAppearance]</div>
</div>

</div>
</body>
</html>

`

export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}